<template>
  <div class="floater">
    <h3>
      SmartyFile is undergoing routine maintenance. Please check back shortly.
    </h3>
    <img src="../../content/images/favicon.svg" id="logo" alt="SmartyFile" />
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "Maintenance",
  data() {
    return {
      timer: "",
    };
  },
  computed: {
    ...mapState("configStore", {
      sgUrl: state => state.mySubmissionsUrl,
    }),
  },
  methods: {
    ...mapActions("configStore", ["fetchMySubmissionsUrl"]),
  },
  mounted() {
    this.fetchMySubmissionsUrl();
    this.timer = setInterval(this.fetchMySubmissionsUrl, 30000); //check server status every 30s
  },
  watch: {
    sgUrl: function (newValue) {
      if (newValue) {
        //server is up, destroy timer
        clearInterval(this.timer);
        //redirect to login
        this.$router.push("/");
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="css">
.floater {
  position: absolute;
  top: 77px;
  left: 0;
  width: 100%;
  height: calc(100vh - 77px - 150px);

  background-color: #eee;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 300px;
  animation: 5s infinite ease-in-out alternate b-icon-animation-fade;
}
</style>
